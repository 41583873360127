<template>
  <Modal :value="true" fullscreen :closable="false" :footer-hide="true">
    <div class="h-full flex justify-center items-center">
      <div class="inline-block text-center">
        <img
          class="mx-auto"
          src="@/assets/logo.png"
          alt="Delight Access Gateway logo"
        />
        <h1 class="p-3 text-5xl">Access Gateway</h1>

        <div class="text-xl text-left mt-6 p-3">
          <p>{{ $t("WELCOME") }} {{ user && user.given_name }}!</p>
          <br />
          <p>
            {{
              $t(
                "IT_SEEMS_THAT_YOUR_ACCOUNT_ISNT_AUTHORIZED_YET_TO_USE_ACCESS_GATEWAY"
              )
            }}.
          </p>
          <p>
            {{ $t("YOU_CAN") }}
            <a
              href="mailto:contact@delight-data.com"
              target="_top"
              class="text-blue-500 underline"
              >{{ $t("CONTACT_US") }}</a
            >
            {{ $t("OR_TRY_TO") }}
            <a href="/" @click="logout" class="text-blue-500 underline">{{
              $t("CONNECT_WITH_ANOTHER_ACCOUNT")
            }}</a
            >.
          </p>
          <br />
          <p>
            {{ $t("SEE_YOU") }},
            <br />
            {{ $t("THE_DELIGHT_TEAM") }}.
          </p>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("auth");

export default {
  computed: mapState(["user"]),
  methods: mapActions(["logout"])
};
</script>

<style scoped></style>
